<template>
  <div id="v-widget">
    <v-card>
      <v-toolbar :color="colortitle" dark flat dense card v-if="enableHeader">
        <v-toolbar-title >
          <v-toolbar-title class="subheading">{{title}}</v-toolbar-title>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <slot name="widget-header-action"></slot>
      </v-toolbar>
      <v-divider v-if="enableHeader"></v-divider>
      <v-card-text>
        <slot name="widget-content"></slot>       
      </v-card-text>       
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'v-widget',
  props: {
    title: {
      type: String
    },
    enableHeader: {
      type: Boolean,
      default: true
    },
    colortitle: {
      type: String,
      default: 'cyan'
    }
  } 
};
</script>

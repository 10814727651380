<template>
    <v-widget title="Datos del cliente">
        <div slot="widget-content">
            <v-text-field
                label="Nombre o Razon social"
                v-model="IsCliente.cli_razsoc"
                :disabled="true"
            ></v-text-field>
            <v-text-field
                label="Telefóno"
                v-model="IsCliente.cli_telefo"
                :disabled="true"
            ></v-text-field>
            <v-text-field
                label="E-mail"
                v-model="IsCliente.cli_email"
                :disabled="true"
            ></v-text-field>
            <v-text-field
                label="Dirección"
                v-model="IsCliente.cli_direcc"
                :disabled="true"
            ></v-text-field>
        </div>
    </v-widget>  
</template>

<script>
import VWidget from '@/components/share/VWidget.vue';
import clienteService from '@/services/ClienteServices';

export default {
    name: 'DatosResponsable',
    components: {VWidget},
    data() {
        return {
            IsCliente: {}
        }
    },
    created() {
        this.MostrarCliente();
    },
    methods: {
        MostrarCliente() {
            this.IsCliente = clienteService.current();
        }
    }
}
</script>
